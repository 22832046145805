import React, {useEffect, useState} from "react";
import {Button, Col, Modal, Row,} from "react-bootstrap";
import AddCardModalSec from "./AddCardModalSec";
import PaymentMethodCard from "./PaymentMethodCard";
import {createNotification} from "react-redux-notify/lib/modules/Notifications";
import {getErrorNotificationMessage} from "../../helper/NotificationMessage";
import {connect} from "react-redux";
import {translate} from "react-multi-lang";
import TipModalSec from "./TipModalSec";
import {sendTipPaypalStart, sendTipStripeStart, sendTipWalletStart,} from "../../../store/actions/SendTipAction";

const SendTipPaymentModal = (props) => {

  const nullData = ["", null, undefined, "light"];
  const [skipRender, setSkipRender] = useState(true);
  const [paymentType, setPaymentType] = useState(localStorage.getItem("default_payment_method"));
  const [selectedCard, setSelectedCard] = useState(null);
  const [showAddCard, setShowAddCard] = useState(false);
  const [tipAmount, setTipAmount] = useState("");
  const [message, setMessage] = useState("");
  

  const paypalOnError = (err) => {
    const notificationMessage = getErrorNotificationMessage(err);
    this.props.dispatch(createNotification(notificationMessage));
  };

  const paypalOnSuccess = (payment) => {
    setTimeout(() => {
      props.dispatch(
        sendTipPaypalStart({
          payment_id: payment.paymentID,
          post_id:
            props.post_id != undefined || props.post_id != null
              ? props.post_id
              : "",
          amount: props.isCampaign==1?props.campaignAmt:tipAmount,
          user_id: props.user_id,
          tips_type: props.isCampaign==1?"campaign":props.type,
          is_campaign : props.isCampaign
        })
      );
    }, 1000);
    props.closepaymentsModal();
  };

  const paypalOnCancel = (data) => {
    const notificationMessage = getErrorNotificationMessage(
      "Payment cancelled please try again.."
    );
    this.props.dispatch(createNotification(notificationMessage));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (paymentType === "CARD")
      props.dispatch(
        sendTipStripeStart({
          post_id:
            props.post_id != undefined || props.post_id != null
              ? props.post_id
              : "",
          amount: props.isCampaign==1?props.campaignAmt:tipAmount,
          message: message,
          user_id: props.user_id,
          user_card_id: selectedCard,
          tips_type: props.isCampaign==1?"campaign":props.type,
          is_campaign : props.isCampaign
        })
      );
    if (paymentType === "WALLET")
      props.dispatch(
        sendTipWalletStart({
          post_id:
            props.post_id != undefined || props.post_id != null
              ? props.post_id
              : "",
          amount: props.isCampaign==1?props.campaignAmt:tipAmount,
          message: message,
          user_id: props.user_id,
          tips_type: props.isCampaign==1?"campaign":props.type,
          is_campaign : props.isCampaign
        })
      );
    // props.closepaymentsModal();
  };

  useEffect(() => {
    if (!skipRender && !props.tipWallet.loading && Object.keys(props.tipWallet.data).length > 0) {
      if(props.isCampaign ==1){
       props.setCampaignAmt()
       props.setIscampaign(0)
      } 
      props.closepaymentsModal();
      
    }
    setSkipRender(false);
  }, [props.tipWallet]);

  return (
    <>
      <div className="payment-modal-sec">
        <Modal
          className={`modal-dialog-center user-list-free-modal payment-modal-res dark-theme-modal`}
          size="xl"
          centered
          show={props.paymentsModal}
          onHide={props.closepaymentsModal}
        >
          {/* <Modal.Header closeButton>
            {/* <Modal.Title>User List</Modal.Title> *
          </Modal.Header> */}
          <Modal.Body className="wallet-card-body">
            <Button className="modal-close"
              onClick={() => props.closepaymentsModal()}>
              <i className="fa fa-times" />
            </Button>
            <div className="payment-modal-body">
              <Row className="justify-content-between">
                <PaymentMethodCard
                  paymentType={paymentType}
                  setPaymentType={setPaymentType}
                  selectedCard={selectedCard}
                  setSelectedCard={setSelectedCard}
                  setShowAddCard={setShowAddCard}
                  tipAmount={props.isCampaign==1?props.campaignAmt:tipAmount}
                />
                <Col md={12} xl={5}>
                  {showAddCard ?
                    <AddCardModalSec
                      setShowAddCard={setShowAddCard}
                    />
                    : <TipModalSec
                      tipAmount={props.isCampaign==1?props.campaignAmt:tipAmount}
                      setTipAmount={setTipAmount}
                      setCampaignAmt = {props.setCampaignAmt}
                      campaignOptions ={props.campaignOptions}
                      isCampaign = {props.isCampaign}
                      message={message}
                      setMessage={setMessage}
                      handleSubmit={handleSubmit}
                      paypalOnSuccess={paypalOnSuccess}
                      paypalOnError={paypalOnError}
                      paypalOnCancel={paypalOnCancel}
                      buttonDisable={props.tipWallet.buttonDisable}
                      loadingButtonContent={props.tipWallet.loadingButtonContent}
                    />
                  }
                </Col>
              </Row>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  // liveVideoDetails: state.liveVideo.singleLiveVideo,
  tipWallet: state.tip.tipWallet,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(SendTipPaymentModal));
