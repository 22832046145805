import React from "react";
import "./LiveStreaming.css";
import LiveStreamingCommentsUserFreeList from "./LiveStreamingCommentsUserFreeList";
import LiveStreamingComments from "./LiveStreamingComments";

const LiveStreamingCommentsFreeIndex = (props) => {

    return (
        <>
            <div className="live-streaming-user-card-right">
                <LiveStreamingCommentsUserFreeList/>
                <LiveStreamingComments/>
            </div>
        </>
    );
};

export default LiveStreamingCommentsFreeIndex;
