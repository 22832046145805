import React, {useState} from "react";
import AuthHeader from "./Header/AuthHeader";
import {Notify} from "react-redux-notify";
import LandingFooter from "./Footer/LandingFooter";

const AuthLayout = (props) => {
  const [themeState, setThemeState] = useState("dark-mode");
  return (
    <body className={"dark-mode"}>
      <Notify position="TopRight" />
      <AuthHeader />
      <div className="landing-main-wrapper">
        {React.cloneElement(props.children)}
      </div>
      <LandingFooter />
    </body>
  )
}

export default AuthLayout;
