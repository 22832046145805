import React, {useEffect} from "react";

function useScript(src) {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = src;
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, [src]);
}

const HomepageLayout = (props) => {


    useScript("/home-assets/js/bootstrap.min.js");
    useScript("/home-assets/js/custom.js");
    const timestamp = new Date().getTime(); //build timestamp
    return (<>
        <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css"/>
        <link rel="stylesheet" type="text/css" href={`/home-assets/css/bootstrap.min.css?t=${timestamp}`}/>
        <link rel="stylesheet" type="text/css" href={`/home-assets/css/slick.css?t=${timestamp}`}/>
        <link rel="stylesheet" type="text/css" href={`/home-assets/css/style.css?t=${timestamp}`}/>
        <link rel="stylesheet" type="text/css" href={`/home-assets/css/responsive.css?t=${timestamp}`}/>

        <div className="main-wrpper home-assets">
            <div className="home-header">
                <div className="container">
                    <nav className="navbar navbar-expand-md navbar-light">
                        <a className="navbar-brand" href="/"><img src="/home-assets/images/logo.svg"
                                                                  alt="Logo"/></a>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                                data-bs-target="#NavbarToggle" aria-controls="NavbarToggle"
                                aria-expanded="false"
                                aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="NavbarToggle">
                            <div className="nav-row">
                                <ul className="navbar-nav">
                                    <li><a className="nav-link" href="https://wickedfans.com/authentication/login">About</a></li>
                                    <li><a className="nav-link" href="https://wickedfans.com/authentication/login">Explore</a></li>
                                    <li><a className="nav-link" href="https://wickedfans.com/authentication/login">Feed</a></li>
                                </ul>
                                <ul className="enter-nav">
                                    <li><a href="/authentication/login" className="btn-nav">Log in </a></li>
                                    <li><a href="/authentication/signup" className="btn-nav signupbtn">Sign up </a></li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>

            {props.children}

            <footer>
                <div className="container">
                    <div className="row">
                        <div className="col-md-5 col-sm-12">
                            <div className="footer-abt">
                                <img src="/home-assets/images/logo.svg" alt="footer-logo"/>

                                <p>A new paradigm in the adult world powered by Crypto & Card Payments.</p>

                                <div className="social-box">
                                    <a href="https://www.tiktok.com/@wickedfanscom" target="_blank"><img src="/home-assets/images/tiktok-ic.png" alt=""/></a>
                                    <a href="https://twitter.com/wickedfanscom" target="_blank"><i className="fab fa-twitter fa-fw"></i></a>
                                    <a href="https://www.facebook.com/wickedfanscom" target="_blank"><i className="fab fa-facebook-f fa-fw"></i></a>
                                    <a href="http://youtube.com/@WickedFans" target="_blank"><i className="fab fa-youtube fa-fw"></i></a>
                                    <a href="http://instagram.com/wickedfans" target="_blank"><i className="fab fa-instagram fa-fw"></i></a>
                                    <a href="https://discord.gg/VHbbCKDheq" target="_blank"><i className="fab fa-discord fa-fw"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-7 col-sm-12">
                            <div className="footer-links">
                                <div className="quicklinks">
                                    <h3>Company</h3>

                                    <ul>
                                        <li><a href="/">Home</a></li>
                                        <li><a href="https://wickedfans.com/authentication/signup">Explore</a></li>
                                        <li><a href="https://wickedfans.com/authentication/signup">Feed</a></li>
                                        <li><a href="https://wickedfans.com/authentication/signup">Discover</a></li>
                                    </ul>
                                </div>
                                <div className="quicklinks">
                                    <h3>Legal</h3>

                                    <ul>
                                        <li><a href="/legal">Legal Policy</a></li>
                                        <li><a href="/terms">Terms Of Service</a></li>
                                        <li><a href="/privacy">Privacy Policy</a></li>
                                        <li><a href="/terms">Under 18 Disclaimer</a></li>
                                        <li><a href="/terms">Creator Agreement</a></li>
                                    </ul>
                                </div>
                                <div className="quicklinks">
                                    <h3>Help</h3>

                                    <ul>
                                        <li><a href="/terms">Contact</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <div className="copyright">
                <div className="container">
                    <p>@2024 <a href="/">WickedFans</a> all right reserved.</p>
                </div>
            </div>
        </div>

    </>);

}

export default HomepageLayout;
