import React from "react";
import {Col, Container, Image, Media, Row,} from "react-bootstrap";
import "./LatestFooter.css";
import {Link} from "react-router-dom";
import configuration from "react-global-configuration";
import {setLanguage, t} from "react-multi-lang";

const LandingFooter = (props) => {
  const handleChangeLang = ({ currentTarget: input }) => {
    console.log(input.value);
    setLanguage(input.value);
    localStorage.setItem("lang", input.value);
    // window.location.reload();
    console.log("=======",configuration)
  };

  return (
    <></>
  );
};

export default LandingFooter;
