import React, {useEffect, useState} from "react";
import {Button, Container, Image} from "react-bootstrap";
import "./Verification.css";
import {useHistory} from "react-router-dom";
import {t, translate} from "react-multi-lang";
import {connect} from "react-redux";
import {registerVerifyResendStart, registerVerifyStart} from '../../store/actions/UserAction';
import {useParams} from "react-router";

const RegisterVerifyIndex = (props) => {
  const params = useParams();

  const [loading, setLoading] = useState(false);
  const [inputData, setInputData] = useState({});
  let history = useHistory();

  const submitVerfication = (event) => {
    event.preventDefault();
    if (inputData.verification_code) {
      props.dispatch(registerVerifyStart({ verification_code: inputData.verification_code }));
    }
  };

  const resendVerfication = (event) => {
    event.preventDefault();
    props.dispatch(registerVerifyResendStart());
  };

  useEffect(() => {
    if (!localStorage.getItem("userId") || !localStorage.getItem("accessToken")) {
      history.push('/');
    } else {
      // props.dispatch(fetchUserDetailsStart());
      if (params.verification_code) {
        props.dispatch(registerVerifyStart({ verification_code: params.verification_code }));
      }
    }
  }, [params]);

  // useEffect(() => {
  //   if (props.user.data.is_email_verified) {
  //     history.push('/home');
  //   }
  // }, [props.user]);

  console.log(inputData.verification_code);
  return (
    <>
      <div className="verification-sec " style={{ marginTop: 68 }}>
        <Container className="verification-center">
          <div className="col-lg-6 col-md-8 col-sm-10 col-xs-12">
            <div className="c-email">
              {/* <div className="c-email-header">
                <h1 className="c-email-header-title">{t("your_verification_code")}</h1>
              </div> */}
              <div className="verificationCodeWrapper">
                <div className="inputsWrapper">
                  <div className="verified-img-sec">
                    <Image
                      src={
                        window.location.origin +
                        "/assets/images/verified-img.svg"
                      }
                      alt=""
                      className="verified-img"
                    />
                  </div>
                  {!props.registerVerify.error ? 
                    <>
                      <p className="c-email-content-text text-italic opacity-30 text-title mt-5 mb-0">
                        Your email address has been successfully verified!
                      </p>
                      <div className="mt-3 text-center">
                        <Button
                          href="/home"
                          className="verify-btn m-0"
                        >
                          Go to Home
                        </Button>
                      </div>
                    </>
                    : 
                    <>
                      <p className="c-email-content-text text-italic opacity-30 text-title mt-5 mb-0">
                        Email verification has been failed. Please resend again!
                      </p>
                      <div className="mt-3 text-center">
                        <Button
                          type="button"
                          className="verify-btn m-0"
                          onClick={resendVerfication}
                        >
                          {props.codeResend.loadingButtonContent != null ? props.codeResend.loadingButtonContent : t("send_again")}
                        </Button>
                      </div>
                    </>
                  }
                  {/* <form onSubmit={submitVerfication}>
                    <VerificationCodeInput
                      length={6}
                      label={t("enter_verification_code")}
                      loading={loading}
                      labelClassName="c-email-content-text text-italic opacity-30 text-title mx-auto h2 mt-5 font-weight-bold"
                      onComplete={(code) => {
                        setLoading(true);
                        setInputData({
                          ...inputData,
                          verification_code: code,
                        });
                      }}
                    />
                    <div className="verify-btn-sec">
                      <Button
                        type="submit"
                        className="verify-btn"
                        onClick={submitVerfication}
                        disabled={props.profileInputData.buttonDisable || inputData.verification_code ? false : true}
                      >
                        {props.profileInputData.loadingButtonContent != null ? props.profileInputData.loadingButtonContent : t("verify")}
                      </Button>
                    </div>
                  </form>
                  <div className="d-flex mt-5 footerLinks align-items-center justify-content-md-center">
                    <p className="c-email-content-text text-italic opacity-30 text-title mb-0">
                      {t("verification_code_resend_info")}
                    </p>
                    <Button
                      type="button"
                      className="verify-btn m-0"
                      onClick={resendVerfication}
                    >
                      {props.codeResend.loadingButtonContent != null ? props.codeResend.loadingButtonContent : t("send_again")}
                    </Button>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  user: state.users.profile,
  profileInputData: state.users.profileInputData,
  codeResend: state.users.registerVerifyResend,
  registerVerify: state.users.registerVerify
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(RegisterVerifyIndex));