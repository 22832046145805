import {useEffect} from 'react';
import {withRouter} from 'react-router-dom';

const ScrollToTop = ({history}) => {
    
    useEffect(() => {

        document.title = "WickedFans";
        const unlisten = history.listen(() => {
            document.title = "WickedFans"; //temp
            document.getElementById('root').scrollTop = 0;
        });
        return () => {
            unlisten();
        };
    }, []);

    return null;
};

export default withRouter(ScrollToTop);
