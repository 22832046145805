import React from "react";
import {Col, Container, Image, Media, Row,} from "react-bootstrap";
import "./LatestFooter.css";
import {Link} from "react-router-dom";
import configuration from "react-global-configuration";
import {setLanguage, t} from "react-multi-lang";

const LatestFooter = (props) => {
    const handleChangeLang = ({currentTarget: input}) => {
        console.log(input.value);
        setLanguage(input.value);
        localStorage.setItem("lang", input.value);
        // window.location.reload();
    };

    return (
        <>
            <div className="latest-footer-sec">
                {/* <Container>
          <Row>
            <Col md={8}>
              <div className="demo-footer-sec">
                <h5>No Credit Card Rquired</h5>
                <h3>Get a Demo of FansForX today!</h3>
                <Form></Form>
              </div>
            </Col>
          </Row>
        </Container> */}
                <footer className="footer-section latest-footer-box">
                    <div className="copyright-area">
                        <Container>
                            <Row>
                                <Col xl={12} lg={12} className="text-center">
                                    <div className="copyright-text">
                                        <p>©2023 <a href="/">WickedFans</a> all right reserved.</p>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </footer>
            </div>
        </>
    );
};

export default LatestFooter;
