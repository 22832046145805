import React, {useEffect,useState} from "react";
import {Col, Container, Image, Row} from "react-bootstrap";
import "./DashboardContentCreator.css";
import {Bar} from "react-chartjs-2";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {fetchContentCreatorDashboardStart} from "../../store/actions/UserAction";
import NoDataFound from "../NoDataFound/NoDataFound";
import {t, translate} from "react-multi-lang";
import PullToRefresh from 'react-simple-pull-to-refresh';
import {fetchChatUsersStart} from "../../store/actions/ChatAction";
import {useMediaQuery} from "react-responsive";
import {
    fetchActiveFollowingStart,
} from "../../store/actions/FollowAction";
const DashboardContentCreator = (props) => {
    const isTablet = useMediaQuery({ maxWidth: 991 });
    const [followers, setfollowers] = useState([]);
    const [filtered, setfilter] = useState(false);

    const initialize = () => {
        return new Promise((resolve) => {
            setTimeout(() => {
                props.dispatch(fetchContentCreatorDashboardStart());
                props.dispatch(fetchActiveFollowingStart());
                resolve();
            }, 1000);
        });
    };

    useEffect(() => {
        initialize();
    }, []);
    const filterData = (data,period) => {

        if(period=='all')
        {
            setfollowers([]);
            setfilter(false)
        }

        else if(period=='week')
        {   let followers=data;
            let today = new Date();
            let priorDate = new Date(new Date().setDate(today.getDate() - 7)).toISOString();
            let result = followers.followers.filter(d => {
                return (d.updated_at >= priorDate);
               });
               setfollowers(result);
               setfilter(true)
        }

        else if(period=='month')
        {   let followers=data;
            let today = new Date();
            let priorDate = new Date(new Date().setDate(today.getDate() - 30)).toISOString();
            let result = followers.followers.filter(d => {
                return (d.updated_at >= priorDate);
               });
               setfollowers(result);
               setfilter(true)
        }
    }
    const state = {
        labels: props.dashboard.loading
            ? ["January", "February", "March", "April", "May"]
            : props.dashboard.data.analytics.last_x_days_month,
        datasets: [
            {
                label: "Revenue",
                backgroundColor: "#fca4bc",
                borderColor: "rgba(0,0,0,1)",
                borderWidth: 2,
                data: props.dashboard.loading
                    ? [65, 59, 80, 81, 56]
                    : props.dashboard.data.analytics.last_x_days_earning,
            },
        ],
    };

    const formatCurrency = (value) => {
    return value.replace('Token','');
    }
    return (
        <>
            {props.dashboard.loading ? null : (
                <div className="dashboard-content-creator-sec">
                    <PullToRefresh
                        isPullable={isTablet}
                        onRefresh={initialize}
                    >
                        <Container>
                            <Row>
                                <Col xl={3} lg={3} md={6} sm={6}>
                                    <div className="dashboard-card bg-black">
                                        <div className="dashboard-icon-sec">
                                            <i className="far fa-star"></i>
                                        </div>
                                        <div className="dashboard-content">
                                            <h6>{t("total_revenue_amount")}</h6>
                                            <p>${formatCurrency(props.dashboard.data.total_payments)}</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={3} lg={3} md={6} sm={6}>
                                    <div className="dashboard-card bg-black">
                                        <div className="dashboard-icon-sec">
                                            <i className="far fa-heart"></i>
                                        </div>
                                        <div className="dashboard-content">
                                            <h6>{t("total_post_amount")}</h6>
                                            <p>${formatCurrency(props.dashboard.data.post_payments)}</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={3} lg={3} md={6} sm={6}>
                                    <div className="dashboard-card bg-black">
                                        <div className="dashboard-icon-sec">
                                            <i className="far fa-map"></i>
                                        </div>
                                        <div className="dashboard-content">
                                            <h6>{t("total_subscription_amount")}</h6>
                                            <p>${formatCurrency(props.dashboard.data.subscription_payments)}</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={3} lg={3} md={6} sm={6}>
                                    <div className="dashboard-card bg-black ">
                                        <div className="dashboard-icon-sec">
                                            <i className="fas fa-usd"></i>
                                        </div>
                                        <div className="dashboard-content">
                                            <h6>{t("total_tips_amount")}</h6>
                                            <p>${formatCurrency(props.dashboard.data.user_tips)}</p>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            
                            <Row>
                                <Col xl={3} lg={3} md={6} sm={6}>
                                    <div className="dashboard-card bg-black">
                                        <div className="dashboard-icon-sec">
                                            <i className="fas fa-video-camera"></i>
                                        </div>
                                        <div className="dashboard-content">
                                            <h6>{t("total_live_streaming_amount")}</h6>
                                            <p>${formatCurrency(props.dashboard.data.live_video_payments)}</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={3} lg={3} md={6} sm={6}>
                                    <div className="dashboard-card bg-black">
                                        <div className="dashboard-icon-sec">
                                            <i className="fas fa-video-camera"></i>
                                        </div>
                                        <div className="dashboard-content">
                                            <h6>{t("total_video_call_amount")}</h6>
                                            <p>${formatCurrency(props.dashboard.data.video_call_payments)}</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={3} lg={3} md={6} sm={6}>
                                    <div className="dashboard-card bg-black">
                                        <div className="dashboard-icon-sec">
                                            <i className="fas fa-phone"></i>
                                        </div>
                                        <div className="dashboard-content">
                                            <h6>{t("total_audio_call_amount")}</h6>
                                            <p>${formatCurrency(props.dashboard.data.audio_call_payments)}</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={3} lg={3} md={6} sm={6}>
                                    <div className="dashboard-card bg-black">
                                        <div className="dashboard-icon-sec">
                                            <i className="fas fa-chart-line"></i>
                                        </div>
                                        <div className="dashboard-content">
                                            <h6>{t("total_chat_asset_amount")}</h6>
                                            <p>${formatCurrency(props.dashboard.data.chat_asset_payments)}</p>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                            <Col md={6}>
                                    <div className="dashboard-box bg-black">
                                        {props.dashboard.loading ? (
                                            "Loading.."
                                        ) : (
                                            <>
                                                <h3 className="mb-3">{t("recent_followers")}</h3>
                                                {props.dashboard.data.followers.length > 0 ? (
                                                    props.dashboard.data.followers.map((follower) => (
                                                        <div className="dashboard-user-card">
                                                            <div className="dashboard-user-img-sec">
                                                                <Image
                                                                    className="dashboard-user-img"
                                                                    src={follower.picture}
                                                                />
                                                            </div>
                                                            <div className="dashboard-user-details">
                                                                <h5>{follower.name}</h5>
                                                                <Link to={`/` + follower.u_unique_id}>
                                                                    @{follower.username}
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    ))
                                                ) : (
                                                    <NoDataFound></NoDataFound>
                                                )}
                                            </>
                                        )}
                                    </div>
                                </Col>
                            <Col md={6}>
                            <div className="dashboard-box bg-black">
                                        {props.activeFollowing.loading ? (
                                            "Loading.."
                                        ) : (
                                            <>
                                            <div class="d-flex justify-content-between"> 
                                                <div class="p-2"><h3 className="mb-3">{t("recent_subscribers")}</h3></div>
                                                <div clas="p-2">
                                                <button className="btn btn-primary subscriber-btn m-2" onClick={()=>filterData(props.activeFollowing.data,'week')}>7 days</button>
                                                <button className="btn btn-primary subscriber-btn m-2" onClick={()=>filterData(props.activeFollowing.data,'month')}>30 days</button>
                                                {filtered && <button className="btn btn-primary subscriber-btn m-2" onClick={()=>filterData(props.activeFollowing.data,'all')}>Clear</button>}
                                                </div>
                                            </div>
                                                
                                                {!filtered && props.activeFollowing.data.followers.length > 0 ? (
                                                    props.activeFollowing.data.followers.map((follower) => (
                                                        <div className="dashboard-user-card">
                                                            <div className="dashboard-user-img-sec">
                                                                <Image
                                                                    className="dashboard-user-img"
                                                                    src={follower.otherUser.picture}
                                                                />
                                                            </div>
                                                            <div className="dashboard-user-details">
                                                                <h5>{follower.otherUser.name}</h5>
                                                                <Link to={`/` + follower.otherUser.u_unique_id}>
                                                                    @{follower.otherUser.username}
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    ))
                                                ) : (
                                                    <NoDataFound></NoDataFound>
                                                )}

                                                {filtered && followers.length > 0 ? (
                                                    followers.map((follower) => (
                                                        <div className="dashboard-user-card">
                                                            <div className="dashboard-user-img-sec">
                                                                <Image
                                                                    className="dashboard-user-img"
                                                                    src={follower.otherUser.picture}
                                                                />
                                                            </div>
                                                            <div className="dashboard-user-details">
                                                                <h5>{follower.otherUser.name}</h5>
                                                                <Link to={`/` + follower.otherUser.u_unique_id}>
                                                                    @{follower.otherUser.username}
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    ))
                                                ) : (
                                                    <NoDataFound></NoDataFound>
                                                )}  
                                            </>
                                        )}
                                    </div>
                                    </Col>
                            </Row>
                            <Row className="margin-to-lg">
                            <Col md={12}>
                                    <div className="dashboard-box">
                                        <h3>{t("last_x_day_revenue")}</h3>
                                        <div className="dashboard-chart-sec">
                                            <div>
                                                <Bar
                                                    data={state}
                                                    options={{
                                                        title: {
                                                            display: true,
                                                            text: t("last_x_day_revenue"),
                                                            fontSize: 20,
                                                        },
                                                        legend: {
                                                            display: true,
                                                            position: "right",
                                                        },
                                                        plugins: {  
                                                            legend: {
                                                              labels: {
                                                                color: "white"
                                                              }
                                                            }
                                                          },
                                                        scales: {
                                                            yAxes:{
                                                                ticks:{
                                                                    color: 'white',
                                                                }
                                                            },
                                                            xAxes: {
                                                                ticks:{
                                                                    color: 'white',
                                                                }
                                                            },
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    </Col>
                            </Row>
                        </Container>
                    </PullToRefresh>
                </div>
            )}
        </>
    );
};

const mapStateToPros = (state) => ({
    dashboard: state.users.dashboard,
    activeFollowing: state.follow.activeFollowing,
});

function mapDispatchToProps(dispatch) {
    return {dispatch};
}

export default connect(
    mapStateToPros,
    mapDispatchToProps
)(translate(DashboardContentCreator));
