import React from "react";
import {translate} from "react-multi-lang";
import {connect} from "react-redux";

const AboutUs = (props) => {


    return (

        <>
            <h1>About us content            </h1>
            <p>Unlock a world of wicked experiences with our creators <br/>
            and pay
securely with your bitcoin wallet!</p>
        </>

    );
};
const mapStateToPros = (state) => ({
    login: state.users.loginInputData,
    signup:
    state.users.registerInputData,
    forgotPassword:
    state.users.forgotPasswordInputData,
    validation:
    state.users.validationInputData,
});

function mapDispatchToProps(dispatch) {
    return {dispatch};
}

export default connect(
    mapStateToPros,
    mapDispatchToProps
)(translate(AboutUs));
