import React, {useEffect, useState} from "react";
import {useMediaQuery} from 'react-responsive';
import {Col, Container, Row,} from "react-bootstrap";
import "./NewHome.css";
import NewEmailVerifiedCard from "./NewEmailVerifiedCard";
import NewFeatureStoryIndex from "./NewFeatureStoryIndex";
import {fetchHomePostsStart, fetchMoreHomePostsStart,} from "../../store/actions/HomeAction";
import {fetchCommentsStart, saveCommentStart,} from "../../store/actions/CommentsAction";
import {saveBookmarkStart} from "../../store/actions/BookmarkAction";
import {connect} from "react-redux";
import {translate} from "react-multi-lang";
import NewHomeSearch from "./NewSingleView/NewHomeSearch";
import HomeLoader from "../Loader/HomeLoader";
import NoDataFound from "../NoDataFound/NoDataFound";
import NewFeedDisplayCard from "./NewFeedDisplayCard";
import InfiniteScroll from "react-infinite-scroll-component";
import NewFeedSuggestionCard from "./NewFeedSuggestionCard";
import PullToRefresh from 'react-simple-pull-to-refresh';
import {fetchChatUsersStart} from "../../store/actions/ChatAction";

const NewHomeIndex = (props) => {

    const [verificationShow, setVerificationShow] = useState(false);
    const isTablet = useMediaQuery({maxWidth: 991});

    const initialize = () => {
        return new Promise((resolve) => {
            setTimeout(() => {
                props.dispatch(
                    fetchHomePostsStart({
                        skip: 0,
                        take: 12,
                    }))
                resolve();
            }, 1000);
        });
    };
    const handleScroll = () => {
        const position = window.pageYOffset;
        if (position == 0 && isTablet) {
            initialize();
        }
    };

    useEffect(() => {
        initialize();

        window.addEventListener('scroll', handleScroll, {passive: true});

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        if (props.loggedInUser.is_email_verified === 0) {
            setVerificationShow(true);
        }
    }, [props.loggedInUser]);

    const fetchMoreData = () => {
        props.dispatch(
            fetchMoreHomePostsStart({
                skip: props.posts.data.posts.length,
                take: 12,
            })
        );
    };

    // const fetchHomeData = () => {
    //   setTimeout(() => {
    //     if (props.posts.length !== 0) {
    //       props.dispatch(fetchHomePostsStart());
    //       setIsFetching(false);
    //     } else {
    //       setNoMoreData(true);
    //     }
    //   }, 3000);
    // };

    // useEffect(() => {
    //   const installGoogleAds = () => {
    //     const elem = document.createElement("script");
    //     elem.src = "//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js";
    //     elem.async = true;
    //     elem.defer = true;
    //     document.body.insertBefore(elem, document.body.firstChild);
    //   };
    //   installGoogleAds();
    // }, []);

    // const [isFetching, setIsFetching] = useInfiniteScroll(fetchHomeData);

    const [noMoreData, setNoMoreData] = useState(false);

    const [sendTip, setSendTip] = useState(false);

    const closeSendTipModal = () => {
        setSendTip(false);
    };

    const [commentInputData, setCommentInputData] = useState({});

    const handleCommentSubmit = (event) => {
        event.preventDefault();
        props.dispatch(saveCommentStart(commentInputData));
    };

    const [isVisible, setIsVisible] = React.useState(true);

    const showCommentSection = (event, post_id) => {
        setCommentInputData({post_id: post_id});
        setIsVisible(true);
        props.dispatch(fetchCommentsStart({post_id: post_id}));
    };

    const handleLike = (event) => {
        event.preventDefault();
    };

    const handleBookmark = (event, post) => {
        event.preventDefault();
        props.dispatch(saveBookmarkStart({post_id: post.post_id}));
    };

    const closeCommentSection = (event) => {
        setIsVisible(false);
    };

    return (
        <>
            <div className="new-home-page-sec" onWheel={handleScroll}>
                <PullToRefresh
                    isPullable={isTablet}
                    onRefresh={initialize}
                >
                    <Container>
                        <Row>
                            <Col md={12}>
                                <div className="new-home-page-box row">
                                    <div className="new-home-page-left col-sm-9 col-md-9 col-lg-6 col-12">
                                        {/* <div className="mobile-visible">
                    <div className="new-feed-search-sec">
                      <InputGroup className="mb-0">
                        <FormControl
                          placeholder="Search"
                          aria-describedby="basic-addon2"
                          onChange={handleSearch}
                        />
                        <InputGroup.Text id="basic-addon2">
                          <Image
                            className="new-feeds-search-icon"
                            src={
                              window.location.origin +
                              "/assets/images/feed-story/search-icon.svg"
                            }
                          />
                        </InputGroup.Text>
                      </InputGroup>
                    
                    </div>
                  </div> */}
                                        <div className="row">
                                            <div className="col-12">
                                                <NewHomeSearch/>
                                                {verificationShow &&
                                                    <NewEmailVerifiedCard verificationShow={verificationShow}
                                                                          setVerificationShow={setVerificationShow}/>}
                                                <NewFeatureStoryIndex/>
                                                {/* <NewFeedIndex /> */}

                                                {props.posts.data.posts.length > 0 ? (
                                                    <InfiniteScroll
                                                        dataLength={props.posts.data.posts}
                                                        next={fetchMoreData}
                                                        hasMore={
                                                            props.posts.data.posts.length <
                                                            props.posts.data.total
                                                        }
                                                        style={{height: "auto", overflow: "hidden"}}
                                                        className="row"
                                                    >
                                                        <div className="new-feed-sec">
                                                            {props.posts.data.posts.map((post, index) => (
                                                                <NewFeedDisplayCard
                                                                    post={post}
                                                                    key={index}
                                                                    index={index}
                                                                />
                                                            ))}
                                                        </div>
                                                    </InfiniteScroll>
                                                ) : (
                                                    <NoDataFound/>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="new-home-page-right col-md-3 col-lg-6">
                                        <div className="new-feed-right-sec">
                                            <NewHomeSearch desktop/>
                                            <div className="new-feed-suggestions-trending-sec">
                                                <NewFeedSuggestionCard/>
                                                {/* <NewFeedTrendingCard /> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </PullToRefresh>
            </div>
        </>
    );
};

const mapStateToPros = (state) => ({
    loading: state.home.homePost.loading,
    posts: state.home.homePost,
    searchUser: state.home.searchUser,
    trendingUsers: state.home.trendingUsers,
    postSug: state.home.postSug,
    loggedInUser: state.home.loggedInUser
});

function mapDispatchToProps(dispatch) {
    return {dispatch};
}

export default connect(
    mapStateToPros,
    mapDispatchToProps
)(translate(NewHomeIndex))