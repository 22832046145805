import React, {useState} from "react";
import {connect} from "react-redux";
import {Button, Col, Container, Row} from "react-bootstrap";
import "./AddBankIndex.css";
import {addBankAccountStart} from "../../../store/actions/BankAccountAction";
import {t, translate} from "react-multi-lang";
import {Link} from "react-router-dom";
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";

const AddBankIndex = (props) => {
  const [inputData, setInputData] = useState({});

  const validationSchema = Yup.object().shape({
    route_number: Yup.string(),
    account_number: Yup.string()
      .required(t("btc_address_required"))
      .min(0, t("btc_address_required")),
    first_name: Yup.string(),
    last_name: Yup.string(),
    bank_type: Yup.string(),
    business_name: Yup.string(),
    acceptTerms: Yup.bool(),
  });

  const handleSubmit = (values) => {
    // event.preventDefault();
    props.dispatch(addBankAccountStart(values));
  };

  return (
    <div className="card-list-sec">
      <Container>
        <Link
          className="bookmarkes-list notify-title back-button head-title"
          onClick={() =>
            props.location.state && props.location.state.prevPath
              ? props.history.goBack()
              : props.history.push("/home")
            // props.history.goBack()
          }
        >
          <img
            src={window.location.origin + "/assets/images/icons/back.svg"}
            className="svg-clone"
          />
          {t("via_bitcoin")}
        </Link>
        {/* <h4 className="head-title">{t("add_bank")}</h4> */}
        <Row>
          <Col sm={12} md={12}>
            <div className="add-bank-box">
              <Formik
                initialValues={{
                  route_number: "",
                  account_number: "",
                  first_name: "",
                  last_name: "",
                  bank_type: "savings",
                  business_name: "",
                  acceptTerms: false,
                }}
                validationSchema={validationSchema}
                onSubmit={(values) => handleSubmit(values)}
              >
                {({ touched, errors, isSubmitting, setFieldValue, values }) => (
                  <Form noValidate>
                    <Col md={6}>
                     <div className="form-group">
                        <label className="form-label">
                          {t("btc_address")}: (*)
                        </label>
                        <Field
                          type="text"
                          className="form-control"
                          placeholder={t("btc_address")}
                          name="account_number"
                          value={values.account_number}
                          autoFocus={true}
                        />
                        <ErrorMessage
                          component={"div"}
                          name="account_number"
                          className="error-msg text-primary text-right"
                        />
                      </div>
                    </Col>

                    <div className="edit-save">
                      <Button
                        className="btn gradient-btn gradientcolor addBank"
                        type="submit"
                        disabled={props.bankAccount.buttonDisable}
                      >
                        {props.bankAccount.loadingButtonContent !== null
                          ? props.bankAccount.loadingButtonContent
                          : t("submit")}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const mapStateToPros = (state) => ({
  bankAccount: state.bankAccount.addBankAccountInput,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(AddBankIndex));
