import React, {useEffect} from "react";
import {useHistory} from "react-router-dom";
import {translate} from "react-multi-lang";
import {connect} from "react-redux";

const Homepage = (props) => {

    const history = useHistory();
    useEffect(() => {
        let userId = localStorage.getItem("userId");
        let accessToken = localStorage.getItem("accessToken");

        if (userId && accessToken) {
            history.push("/home");
        }

    }, [])


    return (

        <>
            <section className="banner-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-sm-12">
                            <div className="banner-data">
                                <div className="main-text">Explore the most <br/>wicked creators<br/> like never before
                                </div>
                                <p>A wicked new experience in the adult world powered by<br/> Bitcoin and your Coinbase wallet.</p>
                                <div className="coinbase">
                                    <img src="/home-assets/images/coinbase.png" alt="coinbase"/>
                                </div>

                                <a href="https://wickedfans.com/authentication/signup" className="btn-main">Become a Creator</a>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <div className="banner-img">
                                <img src="/home-assets/images/1-banner-img.png" alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="sponcers-wrp">
                <div className="container">
                    <div className="sponcer-slider">
                        <div>
                            <div className="sponcer-img">
                                <img src="/home-assets/images/sponcer-img1.png" alt=""/>
                            </div>
                        </div>
                        <div>
                            <div className="sponcer-img">
                                <img src="/home-assets/images/sponcer-img2.png" alt=""/>
                            </div>
                        </div>
                        <div>
                            <div className="sponcer-img">
                                <img src="/home-assets/images/sponcer-img3.png" alt=""/>
                            </div>
                        </div>
                        <div>
                            <div className="sponcer-img">
                                <img src="/home-assets/images/sponcer-img4.png" alt=""/>
                            </div>
                        </div>
                        <div>
                            <div className="sponcer-img">
                                <img src="/home-assets/images/sponcer-img5.png" alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="whyus-wrp">
                <div className="container">
                    <div className="titlebar">
                        <h6>why us</h6>
                        <h2>A Wicked Community</h2>
                        <p>Unlock a world of wicked experiences with our creators <br/>and pay
                            securely with your bitcoin wallet!</p>
                    </div>

                    <div className="row">
                        <div className="col-md-4 col-sm-12">
                            <div className="why-box">
                                <div className="why-ic">
                                    <img src="/home-assets/images/why-ic1.svg" alt=""/>
                                </div>
                                <h3>Wicked creators</h3>
                                <p>Become a wicked creator and gain the ability to create content<br/> like never before!</p>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <div className="why-box">
                                <div className="why-ic">
                                    <img src="/home-assets/images/why-ic2.svg" alt=""/>
                                </div>
                                <h3>Connect with fans</h3>
                                <p>Promote your account and get your first subscribers to engage with messages, live streams
                                    or pay to view content.</p>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <div className="why-box">
                                <div className="why-ic">
                                    <img src="/home-assets/images/why-ic3.svg" alt=""/>
                                </div>
                                <h3>Earn money</h3>
                                <p>85% of everything you make can be withdrawn as soon as it hits <br/>your balance through the power of bitcoin!
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="ambassadors-wrp">
                <div className="container">
                    <div className="titlebar">
                        <h6>Creators</h6>
                        <h2>Wicked Creators</h2>
                        <p>Unlock a world of wicked experiences with our creators <br/> and pay
                            securely with bitcoin!</p>
                    </div>

                    <div className="ambassadors-slider">
                        <div className="ambassadors-block">
                            <div className="ambassadors-img">
                                <img src="/home-assets/images/irina.png" alt=""/>
                            </div>

                            <div className="personal-infobx">
                                <h3>Irina</h3>
                                <h6>@irina</h6>

                                <div className="connect-us">
                                    <a href=""><img src="/home-assets/images/social-ic1.png"
                                                                     alt=""/></a>
                                    <a href="" ><img src="/home-assets/images/social-ic2.png"
                                                                     alt=""/></a>
                                    <a href=""><img src="/home-assets/images/social-ic3.png"
                                                                     alt=""/></a>
                                </div>
                            </div>
                        </div>

                        <div className="ambassadors-block">
                            <div className="ambassadors-img">
                                <img src="/home-assets/images/chiara.png" alt=""/>
                            </div>

                            <div className="personal-infobx">
                                <h3>Chiara</h3>
                                <h6>@chiara</h6>

                                <div className="connect-us">
                                    <a href="" ><img src="/home-assets/images/social-ic1.png"
                                                                     alt=""/></a>
                                    <a href="" ><img src="/home-assets/images/social-ic2.png"
                                                                     alt=""/></a>
                                    <a href="" ><img src="/home-assets/images/social-ic3.png"
                                                                     alt=""/></a>
                                </div>
                            </div>
                        </div>

                        <div className="ambassadors-block">
                            <div className="ambassadors-img">
                                <img src="/home-assets/images/sofia.png" alt=""/>
                            </div>

                            <div className="personal-infobx">
                                <h3>Sofia</h3>
                                <h6>@sofia</h6>

                                <div className="connect-us">
                                    <a href="" ><img src="/home-assets/images/social-ic1.png"
                                                                     alt=""/></a>
                                    <a href="" ><img src="/home-assets/images/social-ic2.png"
                                                                     alt=""/></a>
                                    <a href="" ><img src="/home-assets/images/social-ic3.png"
                                                                     alt=""/></a>
                                </div>
                            </div>
                        </div>

                        <div className="ambassadors-block">
                            <div className="ambassadors-img">
                                <img src="/home-assets/images/lillie.png" alt=""/>
                            </div>

                            <div className="personal-infobx">
                                <h3>Lillie</h3>
                                <h6>@lillie</h6>

                                <div className="connect-us">
                                    <a href="" ><img src="/home-assets/images/social-ic1.png"
                                                                     alt=""/></a>
                                    <a href="" ><img src="/home-assets/images/social-ic2.png"
                                                                     alt=""/></a>
                                    <a href="" ><img src="/home-assets/images/social-ic3.png"
                                                                     alt=""/></a>
                                </div>
                            </div>
                        </div>

                        <div className="ambassadors-block">
                            <div className="ambassadors-img">
                                <img src="/home-assets/images/andrea.png" alt=""/>
                            </div>

                            <div className="personal-infobx">
                                <h3>Andrea</h3>
                                <h6>@andrea</h6>

                                <div className="connect-us">
                                    <a href=""><img src="/home-assets/images/social-ic1.png"
                                                                     alt=""/></a>
                                    <a href="" ><img src="/home-assets/images/social-ic2.png"
                                                                     alt=""/></a>
                                    <a href=""><img src="/home-assets/images/social-ic3.png"
                                                                     alt=""/></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="creation-wrp">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-sm-12">
                            <div className="creation-img">
                                <img src="/home-assets/images/creation-img.png" alt=""/>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <div className="creation-data">
                                <h6>creators</h6>
                                <h2>Enjoy the most wicked selection of content through</h2>

                                <ul>
                                    <li>Chat with your favorite creator</li>
                                    <li>PPVs</li>
                                    <li>Subscriptions</li>
                                    <li>Custom Content</li>
                                </ul>

                                <div className="coinbase">
                                    <img src="/home-assets/images/coinbase.png" alt="coinbase"/>
                                </div>

                                <p>All while paying with Bitcoin through your wallet.</p>

                                <div className="btn-group">
                                    <a href="https://wickedfans.com/authentication/signup" className="btn-main">Become a Creator</a>
                                    <a href="https://wickedfans.com/authentication/signup" className="btn-main browsebtn">start browing</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="signup-wrp">
                <div className="container">
                    <div className="signup-row">
                        <div className="row">
                            <div className="col-md-7 col-sm-12">
                                <div className="signup-data">
                                    <h2>Earn up to 95%<br/>
                                     on WickedFans</h2>
                                    <p>Join the fastest growing platform in the creator economy and get the most ever payouts in the industry!</p>

                                    <div className="btn-group">
                                        <a href="https://wickedfans.com/authentication/signup" className="btn-main">Become a Creator</a>
                                        <a href="https://wickedfans.com/authentication/signup" className="btn-main browsebtn">start browing</a>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-5 col-sm-12">
                                <div className="signup-img">
                                    <img src="/home-assets/images/signup-img.png" alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
            <section className="testimonial-wrp">
                <div className="container">
                    <div className="titlebar">
                        <h6>testimonials</h6>
                        <h2>Don't Take our Word for it</h2>
                        <p>Thousands of  members have enjoyed our wicked content, Here are just a few of their stories:</p>
                    </div>

                    <div className="testimonials-slider">
                        <div className="testimonials-box">
                            <div className="raitingbx">
                                <img src="/home-assets/images/star-ic.svg" alt=""/>
                                <img src="/home-assets/images/star-ic.svg" alt=""/>
                                <img src="/home-assets/images/star-ic.svg" alt=""/>
                                <img src="/home-assets/images/star-ic.svg" alt=""/>
                                <img src="/home-assets/images/star-ic.svg" alt=""/>
                            </div>

                            <p>"I never thought I would be able to make this much money, and enjoy creating way more wicked content than was allowed on Onlyfans!"</p>

                            <h3>Abby L.</h3>
                            <h6>@abby </h6>
                        </div>
                        <div className="testimonials-box">
                            <div className="raitingbx">
                                <img src="/home-assets/images/star-ic.svg" alt=""/>
                                <img src="/home-assets/images/star-ic.svg" alt=""/>
                                <img src="/home-assets/images/star-ic.svg" alt=""/>
                                <img src="/home-assets/images/star-ic.svg" alt=""/>
                                <img src="/home-assets/images/star-ic.svg" alt=""/>
                            </div>

                            <p>"No other site can compete. WickedFans is going places and I'm all for it. After Onlyfans locked up my money, I told myself I was never looking back. WickedFans came just in time!"</p>

                            <h3>Andrea M.</h3>
                            <h6>@andrea </h6>
                        </div>
                        <div className="testimonials-box">
                            <div className="raitingbx">
                                <img src="/home-assets/images/star-ic.svg" alt=""/>
                                <img src="/home-assets/images/star-ic.svg" alt=""/>
                                <img src="/home-assets/images/star-ic.svg" alt=""/>
                                <img src="/home-assets/images/star-ic.svg" alt=""/>
                                <img src="/home-assets/images/star-ic.svg" alt=""/>
                            </div>

                            <p>"What a kinky idea, I can finally support my favorite creators privately with crypto! I hated using credit cards especially when my wife finds out lol!"</p>

                            <h3>Max P.</h3>
                            <h6>@maxp </h6>
                        </div>
                        <div className="testimonials-box">
                            <div className="raitingbx">
                                <img src="/home-assets/images/star-ic.svg" alt=""/>
                                <img src="/home-assets/images/star-ic.svg" alt=""/>
                                <img src="/home-assets/images/star-ic.svg" alt=""/>
                                <img src="/home-assets/images/star-ic.svg" alt=""/>
                                <img src="/home-assets/images/star-ic.svg" alt=""/>
                            </div>

                            <p>"I've connected and met so many wicked creators. I didn't even know I was turned on by some of the things I saw inside. My new favorite site for fetishes and more."</p>

                            <h3>Felix T.</h3>
                            <h6>@felixt </h6>
                        </div>
                        <div className="testimonials-box">
                            <div className="raitingbx">
                                <img src="/home-assets/images/star-ic.svg" alt=""/>
                                <img src="/home-assets/images/star-ic.svg" alt=""/>
                                <img src="/home-assets/images/star-ic.svg" alt=""/>
                                <img src="/home-assets/images/star-ic.svg" alt=""/>
                                <img src="/home-assets/images/star-ic.svg" alt=""/>
                            </div>

                            <p>"Been a member for just 4 months so far and have seen crazier things than in several years on onlyfans. Definitely a unique experience and once you sign up you'll understand why its called Wickedfans!"</p>

                            <h3>Tabby S.</h3>
                            <h6>@felixt </h6>
                        </div>
                    </div>
                </div>


            </section>
            <section className="faq-wrapper">
                <div className="container">
                    <div className="titlebar">
                        <h6>faq’s</h6>
                        <h2>Frequently asked questions</h2>
                        <p>You got questions, we got answers:</p>
                    </div>

                    <div className="faq-list">
                        <div className="accordion" id="accordionExample">
                            <div className="accordion-item line-border">
                                <h2 className="accordion-header" id="headingfaq1">
                                    <button className="accordion-button" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapsefaq1" aria-expanded="true"
                                            aria-controls="collapsefaq1">
                                        What are we?
                                    </button>
                                </h2>
                                <div id="collapsefaq1" className="accordion-collapse collapse show"
                                     aria-labelledby="headingfaq1" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        <p>Wickedfans is a subscription social platform that empowers all creators
                                            to share,
                                            earn and connect with their fans. We are built for creators and
                                            optimizer for
                                            fans</p>
                                    </div>
                                </div>
                            </div>

                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingfaq2">
                                    <button className="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapsefaq2" aria-expanded="false"
                                            aria-controls="collapsefaq2">
                                        Who can become a creator?
                                    </button>
                                </h2>
                                <div id="collapsefaq2" className="accordion-collapse collapse"
                                     aria-labelledby="headingfaq2"
                                     data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        <p>Wickedfans is a subscription social platform that empowers all creators
                                            to share,
                                            earn and connect with their fans. We are built for creators and
                                            optimizer for
                                            fans</p>
                                    </div>
                                </div>
                            </div>

                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingfaq3">
                                    <button className="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapsefaq3" aria-expanded="false"
                                            aria-controls="collapsefaq3">
                                    How much can i make?</button>
                                </h2>
                                <div id="collapsefaq3" className="accordion-collapse collapse"
                                     aria-labelledby="headingfaq3"
                                     data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        <p>Wickedfans is a subscription social platform that empowers all creators
                                            to share,
                                            earn and connect with their fans. We are built for creators and
                                            optimizer for
                                            fans</p>
                                    </div>
                                </div>
                            </div>

                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingfaq4">
                                    <button className="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapsefaq4" aria-expanded="false"
                                            aria-controls="collapsefaq4">
                                        How long does it take to become a creator?
                                    </button>
                                </h2>
                                <div id="collapsefaq4" className="accordion-collapse collapse"
                                     aria-labelledby="headingfaq4"
                                     data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        <p>Wickedfans is a subscription social platform that empowers all creators
                                            to share,
                                            earn and connect with their fans. We are built for creators and
                                            optimizer for
                                            fans</p>
                                    </div>
                                </div>
                            </div>

                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingfaq5">
                                    <button className="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapsefaq5" aria-expanded="false"
                                            aria-controls="collapsefaq5">
                                        How fast can I get paid out?
                                    </button>
                                </h2>
                                <div id="collapsefaq5" className="accordion-collapse collapse"
                                     aria-labelledby="headingfaq5"
                                     data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        <p>Wickedfans is a subscription social platform that empowers all creators
                                            to share,
                                            earn and connect with their fans. We are built for creators and
                                            optimizer for
                                            fans</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
            <section className="signup-wrp crypto-wrp">
                <div className="container">
                    <div className="signup-row">
                        <div className="row">
                            <div className="col-md-7 col-sm-12">
                                <div className="signup-data">
                                    <h2>Pay Through Bitcoin</h2>
                                    <p>We know anonymity is important that's why we are directly integrated with Bitcoin and your Coinbase wallet. Experience wicked content like never before!</p>

                                    <h6>powered & integrated with</h6>
                                    <div className="coinbase">
                                        <img src="/home-assets/images/coinbase.png" alt="coinbase"/>
                                    </div>

                                    <div className="btn-group">
                                        <a href="https://wickedfans.com/authentication/signup" className="btn-main">Become a Creator</a>
                                        <a href="https://wickedfans.com/authentication/signup" className="btn-main browsebtn">start browing</a>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-5 col-sm-12">
                                <div className="signup-img">
                                    <img src="/home-assets/images/crypto-img.png" alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="modal fade" id="exampleModal" data-bs-backdrop="static" tabIndex="-1"
                 aria-labelledby="exampleModalLabel" role="dialog" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="model-img">
                                <img src="/home-assets/images/logo.svg" alt="logo"/>
                            </div>
                            <div className="model-content">
                                <div className="banner-data">
                                    <h1>Age Verification</h1>
                                </div>
                                <div className="banner-data">
                                    <p>WickedFans is an adult community that contains sexually explicit content. You must
                                        be 18
                                        year old or over to enter.</p>
                                </div>
                                <div className="enter-link">
                                    <a href="index.html">I am 18 or older - Enter</a>
                                </div>
                                <div className="banner-data">
                                    <p>Our <span>parental control page </span> explains how you can easliy block access
                                        to this
                                        site.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};
const mapStateToPros = (state) => ({
    login: state.users.loginInputData,
    signup:
    state.users.registerInputData,
    forgotPassword:
    state.users.forgotPasswordInputData,
    validation:
    state.users.validationInputData,
});

function mapDispatchToProps(dispatch) {
    return {dispatch};
}

export default connect(
    mapStateToPros,
    mapDispatchToProps
)(translate(Homepage));
