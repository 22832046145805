import React from "react";
import {translate} from "react-multi-lang";
import {connect} from "react-redux";

const Help = (props) => {

    return (

        <>
            <h1>Help content</h1>
        </>
    );
};
const mapStateToPros = (state) => ({
    login: state.users.loginInputData,
    signup:
    state.users.registerInputData,
    forgotPassword:
    state.users.forgotPasswordInputData,
    validation:
    state.users.validationInputData,
});

function mapDispatchToProps(dispatch) {
    return {dispatch};
}

export default connect(
    mapStateToPros,
    mapDispatchToProps
)(translate(Help));
