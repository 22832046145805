import React, {useEffect, useRef, useState} from "react";
import {Link} from "react-router-dom";
import {Button, Col, Container, Form, Image, Row,} from "react-bootstrap";
import {connect} from "react-redux";
import {
    fetchPostCategoriesStart,
    postFileRemoveStart,
    postFileUploadStart,
    savePostStart,
} from "../../../store/actions/PostAction";

import {createNotification} from "react-redux-notify/lib/modules/Notifications";
import {getErrorNotificationMessage} from "../../helper/NotificationMessage";
import {t, translate} from "react-multi-lang";
// import ContentEditable from 'react-contenteditable'
// import { set } from "date-fns/esm";
import {Multiselect} from "multiselect-react-dropdown";
import ContentCreatorSteps from "./ContentCreatorSteps";
import "./CreatePost.css"
import 'quill/dist/quill.snow.css'
import ReactQuill from 'react-quill'

const CreatePostIndex = (props) => {

    const [inputData, setInputData] = useState({});
    const [postFileData, setPostFileData] = useState([]);
    const [previewImage, setPreviewImage] = useState(false);
    const [videoPreviewUrl, setVideoPreviewUrl] = useState(false);

    const [paidPost, setPaidPost] = useState(false);
    const [videoTitle, setVideoTitle] = useState("");

    const [fileUploadStatus, setFileUploadStatus] = useState(false);

    const [videoThumbnailStatus, setVideoThumbnailStatus] = useState(false);

    const mentionsRef = useRef();

    // const [editorContentState, setEditorContentstate] = useState("");

    const [editorHtmlContent, setEditorHtmlContent] = useState("");

    const [disableImage, setDisableImage] = useState(false);

    const [disableVideo, setDisableVideo] = useState(false);

    const [videoThumbnail, setVideoThumbnail] = useState({videoPreviewImage: ""});

    const [audioTitle, setAudioTitle] = useState("");

    const [audioThumbnail, setAudioThumbnail] = useState(false);

    const [disableAudio, setDisableAudio] = useState(false);

    const [videoPreview, setVideoPreview] = useState({previewVideo: ""});

    const [isCampaign, setChecked] = React.useState(false);

    const [multipleInputs, setMultipleInputs] = useState([
        {
            name: ""
        }
    ])

    const [multipleInputError, setMultipleInputError] = useState(false)

    const [campaignGoalAmount, setCampaignAmount] = useState('');

    const [addOptionMsg, setAddOption] = useState('+ Add upto 4 prefered options');

    const [errorCount, setErrorCount] = useState(0);

    const [postType, setPostType] = useState('image');

    const modules = {
        toolbar: [
            ["bold", "italic", "underline", "strike",],
        ]
    };

    const formats = [
        "header", "height", "bold", "italic",
        "underline", "strike", "blockquote",
        "list", "color", "bullet", "indent",
        "link", "image", "align", "size",
    ];

    const imageFormats = '.gif,.jpg,.jpeg,.gif,.png,.jpg,.jpeg,.png';
    const videoFormats = 'video/mp4,video/x-m4v,video/*';
    const audioFormats = 'audio/mp3,audio/*';

    const handleProcedureContentChange = (content) => {
        setEditorHtmlContent(content);
    };

    useEffect(() => {
        props.dispatch(fetchPostCategoriesStart());
    }, []);

    useEffect(() => {
        if (props.fileUpload.loading === false && props.fileUpload.data.post_file.length > 0) {
            let files = [];
            props.fileUpload.data.post_file.map((value, i) => {
                files.push(value.post_file);
            });
            setPostFileData(files);
            setInputData({
                ...inputData,
                post_file_id: props.fileUpload.data.post_file_id,
            });
        }
    }, [!props.fileUpload.loading]);

    useEffect(() => {
        if (props.fileRemove.loading === false) {
            let files = [];
            if (props.fileRemove.data.post_file.length > 0) {
                props.fileRemove.data.post_file.map((value, i) => {
                    files.push(value);
                });
            } else {
                setFileUploadStatus(false);
                setDisableVideo(false);
                setDisableAudio(false);
                setPaidPost(false);
                setDisableImage(false);
                setPreviewImage(false);
                setVideoPreviewUrl(false);
                setVideoThumbnailStatus(false);
                setAudioThumbnail(false);
            }
            setInputData({
                ...inputData,
                post_file_id: props.fileRemove.data.post_file_id,
            });
            setPostFileData(files);
        }
    }, [!props.fileRemove.loading]);

    const handleChangeImage = (event, fileType) => {
        let data_obj = {};

        [...event.target.files].forEach((file, key) => {

            let name = 'file[' + key + ']';

            data_obj[name] = file;

        });
        data_obj['file_type'] = fileType;

        setPreviewImage(true);
        setFileUploadStatus(true);
        setPaidPost(true);
        setDisableVideo(true);
        setDisableAudio(true);
        props.dispatch(postFileUploadStart(data_obj));
    };

    const handleChangeVideo = (event, fileType) => {
        let data_obj = {};

        var files = event.target.files[0];
        var video = document.createElement('video');
        video.preload = 'metadata';

        [...event.target.files].forEach((file, key) => {

            let name = 'file[' + key + ']';

            data_obj[name] = file;

        });

        data_obj['file_type'] = fileType;

        video.onloadedmetadata = function () {
            data_obj['duration'] = parseInt(video.duration);

            setPaidPost(true);
            setFileUploadStatus(true);
            setVideoThumbnailStatus(true);
            setDisableImage(true);
            setDisableAudio(true);
            setVideoPreviewUrl(true);
            props.dispatch(postFileUploadStart(data_obj));
        };
        video.src = URL.createObjectURL(files);
    };

    const handleChangeAudio = (event, fileType) => {
        let data_obj = {};

        [...event.target.files].forEach((file, key) => {

            let name = 'file[' + key + ']';

            data_obj[name] = file;

        });

        data_obj['file_type'] = fileType;
        setFileUploadStatus(true);
        setPaidPost(true);
        setAudioThumbnail(true);
        setDisableImage(true);
        setDisableVideo(true);
        props.dispatch(postFileUploadStart(data_obj));
    };

    const handleUpload = (event, fileType) => {
        switch (fileType) {
            case 'image':
                handleChangeImage(event, fileType);
                break;
            case 'video':
                handleChangeVideo(event, fileType);
                break;
            case 'audio':
                handleChangeAudio(event, fileType);
                break;
        }
    }

    const handleClose = (event, post_file) => {
        event.preventDefault();
        if (props.fileUpload.loadingButtonContent !== null) {
            const notificationMessage = getErrorNotificationMessage(
                "File is being uploaded.. Please wait"
            );
            props.dispatch(createNotification(notificationMessage));
        } else {
            setPostFileData([]);
            props.dispatch(
                postFileRemoveStart({
                    file: post_file,
                    // file_type: props.fileUpload.data.post_file.file_type,
                    // blur_file: props.fileUpload.data.post_file.blur_file,
                    post_file_id: inputData.post_file_id,
                })
            );
        }
    };

    const handleCheckBox = (e) => {
        setVideoThumbnailStatus(e.target.checked);
    }

    const handleChange = (e) => {
        //alert(e.target.checked)
        setChecked(e.target.checked);
    }

    const handleAmountChange = (e) => {
        e.preventDefault()
        const {value, name} = e.target
        setCampaignAmount(value)
    }


    const handleSubmit = (event) => {
        event.preventDefault();
        if (fileUploadStatus) {
            props.dispatch(
                savePostStart({
                    content: editorHtmlContent,
                    amount: inputData.amount ? inputData.amount : "",
                    post_file_id: inputData.post_file_id ? inputData.post_file_id : "",
                    preview_file: inputData.preview_file ? inputData.preview_file : "",
                    category_ids: inputData.category_ids ? inputData.category_ids : [],
                    video_preview_file: inputData.video_preview_file ? inputData.video_preview_file : "",
                    is_campaign: isCampaign ? 1 : 0,
                    campaign_goal_amt: campaignGoalAmount,
                    campaign_options: multipleInputs
                })
            );
        } else {
            props.dispatch(
                savePostStart({
                    content: editorHtmlContent,
                    amount: inputData.amount ? inputData.amount : "",
                    category_ids: inputData.category_ids ? inputData.category_ids : [],
                    is_campaign: isCampaign ? 1 : 0,
                    campaign_goal_amt: campaignGoalAmount,
                    campaign_options: multipleInputs

                })
            );
            const notificationMessage = getErrorNotificationMessage(
                "Please upload media files"
            );
            props.dispatch(createNotification(notificationMessage));
        }
    };

    const setValues = (inputValue) => {
        let user_id_arr = [];
        inputValue.map((value, i) => {
            user_id_arr.push(value.product_category_id);
        });
        setInputData({
            ...inputData,
            category_ids: user_id_arr,
        });
    };

    const handleVideopreviewImage = (event) => {
        if (event.currentTarget.type === "file") {
            setFileUploadStatus(true);
            let reader = new FileReader();
            let file = event.currentTarget.files[0];
            reader.onloadend = () => {
                setVideoThumbnail({...videoThumbnail, videoPreviewImage: reader.result});
            };

            if (file) {
                reader.readAsDataURL(file);
            }

            setInputData({
                ...inputData,
                preview_file: file,
            });
        }
    };

    const handleVideoPreview = (event) => {
        if (event.currentTarget.type === "file") {
            let reader = new FileReader();
            let file = event.currentTarget.files[0];
            reader.onloadend = () => {
                setVideoPreview({...videoPreview, previewVideo: reader.result});
            };

            if (file) {
                reader.readAsDataURL(file);
            }

            setInputData({
                ...inputData,
                video_preview_file: file,
            });
        }
    };
    const addInputFields = (e) => {
        e.preventDefault()
        if (multipleInputs.length < 4) {
            let newfield = {name: ''}
            setMultipleInputs([...multipleInputs, newfield])
        } else {
            setAddOption("You can add maximum 4 option")
        }
    }

    const removeInputFields = (e, index) => {
        e.preventDefault()
        setAddOption('+ Add upto 4 prefered options')
        let data = [...multipleInputs];
        data.splice(index, 1)
        setMultipleInputs(data)

    }

    const handleMultipleInputChange = (evnt, index) => {
        const {name, value} = evnt.target;
        let list = [...multipleInputs];
        list[index] = value;
        if (parseFloat(value) < parseFloat(campaignGoalAmount)) {
            setMultipleInputs(list);
            setMultipleInputError(false)
        } else {
            setMultipleInputError(true)
        }
    }

    const handlePostTypeChange = (value) => {
        if (value !== postType) {
            resetMediaData();
        }

        setPostType(value);
    }

    const resetMediaData = () => {
        setFileUploadStatus(false);
        setPreviewImage(false);
        setVideoPreviewUrl(false);
        setVideoThumbnailStatus(false);
        setAudioThumbnail(false);
        setPostFileData([]);
    }

    return (
        <div className="notification-page create-post" id="tabs">
            <Container>
                {localStorage.getItem("is_content_creator") == 2 ? (
                    <div className="create-post-box">
                        <Form onSubmit={handleSubmit}>
                            <Row>
                                <Col sm={12} md={12}>
                                    <div className="post-create-header">
                                        <div className="pull-left">
                                            <Link
                                                className="bookmarkes-list notify-title"
                                                to={"/home"}
                                            >
                                                <Image
                                                    src={
                                                        window.location.origin +
                                                        "/assets/images/icons/back.svg"
                                                    }
                                                    className="svg-clone"
                                                />
                                                {t("new_post")}
                                            </Link>
                                        </div>
                                        <div className="pull-right">
                                            {localStorage.getItem("is_content_creator") == 2 ? (
                                                <Button
                                                    type="submit"
                                                    className="btn gradient-btn postBtn gradientcolor text-uppercase mt-0 mt-md-3"
                                                    onClick={handleSubmit}
                                                    disabled={
                                                        props.fileUpload.buttonDisable ||
                                                        props.savePost.buttonDisable
                                                    }
                                                >
                                                    {props.fileUpload.loadingButtonContent !== null
                                                        ? props.fileUpload.loadingButtonContent
                                                        : props.savePost.loadingButtonContent !== null
                                                            ? props.savePost.loadingButtonContent
                                                            : t("post")}
                                                </Button>
                                            ) : (
                                                <Button
                                                    type="submit"
                                                    className="btn gradient-btn postBtn gradientcolor text-uppercase mt-0 mt-md-3"
                                                    disabled="true"
                                                >
                                                    {t("post")}
                                                </Button>
                                            )}
                                        </div>
                                    </div>
                                </Col>
                                <Col sm={12} md={12}>
                                    {/* <div className="create-post-textarea">
                <Form.Group>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder={t("new_post_placeholder")}
                    name="content"
                    style={{ width: "100%", maxWidth: "100%" }}
                    value={inputData.content ? inputData.content : null}
                    onChange={(event) =>
                      setInputData({
                        ...inputData,
                        content: event.currentTarget.value,
                      })
                    }
                  />
                </Form.Group>
              </div> */}
                                    <div className="searchMentions">
                                        <div className="PostEditor">
                                            {/* <PostEditor
                        className="PostEditor__input"
                        placeholder={t("new_post_placeholder")}
                        ref={mentionsRef}
                        getEditorRawContent={setEditorContentstate}
                        getEditorHtmlContent={setEditorHtmlContent}
                        dispatch={props.dispatch}
                      // searchUser={props.searchUser}
                      /> */}
                                            <ReactQuill
                                                theme="snow"
                                                modules={modules}
                                                formats={formats}
                                                placeholder={t("new_post_placeholder")}
                                                onChange={handleProcedureContentChange}
                                                // style={{ height: "220px" }}
                                            >
                                            </ReactQuill>
                                        </div>
                                    </div>
                                </Col>

                                <Col sm={12} md={6} className="mt-3 mt-lg-4">
                                    {props.postCategories.data.post_categories &&
                                    props.postCategories.data.post_categories.length > 0 ? (
                                        <>
                                            <Form.Group className="mb-0">
                                                <Form.Label className="edit-input-label mb-3 mb-lg-3">
                                                    {t("category")} ({t("optional")})
                                                </Form.Label>
                                                {props.postCategories.data.post_categories ? (
                                                    <Multiselect
                                                        name="category_ids"
                                                        options={props.postCategories.data.post_categories}
                                                        displayValue="name"
                                                        avoidHighlightFirstOption="true"
                                                        placeholder={t("choose_category")}
                                                        onSelect={(values) => setValues(values)}
                                                    />
                                                ) : null}
                                            </Form.Group>
                                        </>
                                    ) : (
                                        ""
                                    )}

                                    {paidPost == true ? (
                                        <Form.Group className="md-mrg-btm mt-3 mt-lg-4">
                                            <label className="text-muted m-1 mb-3 mb-lg-3">
                                                {t("price")} ({t("optional")})
                                            </label>
                                            <Form.Control
                                                type="number"
                                                placeholder={t("price_placeholder")}
                                                name="amount"
                                                pattern="[0-9]*"
                                                min="1"
                                                inputmode="numeric"
                                                value={inputData.amount}
                                                width="50%"
                                                onChange={(event) =>
                                                    setInputData({
                                                        ...inputData,
                                                        amount: event.currentTarget.value,
                                                    })
                                                }
                                            />
                                            {/* <div className="check-preview">
                        <input type="checkbox" className="check-preview-box" checked={videoThumbnailStatus} onChange={handleCheckBox} />
                        <span className="check-preview-content">Show preview video or optional thumbnail</span>
                      </div> */}
                                        </Form.Group>
                                    ) : (
                                        ""
                                    )}
                                    {videoPreviewUrl &&
                                        <div>
                                            <Form.Group className="md-mrg-btm mb-3 mb-lg-3">
                                                <label className="text-muted m-1 mt-3 f-12 text-uppercase mb-3 mb-lg-3">
                                                    {t("upload_post_preview")}
                                                </label>
                                                <Form.Control
                                                    style={{display: "block"}}
                                                    type="file"
                                                    placeholder={t("upload_post_preview_placeholder")}
                                                    name="preview_file"
                                                    width="50%"
                                                    className="form-control"
                                                    accept="video/mp4,video/x-m4v,video/*,.gif,.jpg,.jpeg,.gif,.png,.jpg,.jpeg,.png"
                                                    onChange={(event) => handleVideoPreview(event)}
                                                />
                                            </Form.Group>
                                            {videoPreview.previewVideo !== "" ? (
                                                <>
                                                    {inputData.video_preview_file.type.indexOf('video') !== -1 ? (
                                                        <Row>
                                                            <Col sm={12} md={6} className="mb-3 mb-lg-4">
                                                                <div className="post-img-preview-sec m-0">
                                                                    <div className="post-img-preview-sec my-3 my-lg-4">
                                                                        <video
                                                                            autoplay
                                                                            controls
                                                                            id="myVideo"
                                                                            className="user-profile1 w-100"
                                                                        >
                                                                            <source src={videoPreview.previewVideo}
                                                                                    type="video/mp4"/>
                                                                        </video>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    ) : (
                                                        <Row>
                                                            <Col sm={12} md={6} className="mb-3 mb-lg-4">
                                                                <div className="post-img-preview-sec m-0">
                                                                    <div className="post-img-preview-sec my-3 my-lg-4">
                                                                        <Image
                                                                            className="sidebar-user-img profile-image"
                                                                            src={videoPreview.previewVideo}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    )}
                                                </>
                                            ) : null}
                                        </div>
                                    }
                                    {/* ) : ""} */}
                                    {/* {videoThumbnailStatus === true ? (
                    <>
                      <Form.Group className="md-mrg-btm mb-3 mb-lg-3">
                        <label className="text-muted m-1 mt-3 f-12 text-uppercase mb-3 mb-lg-3">
                          {t("upload_video_thumbnail")}:({t("optional")})
                        </label>
                        <Form.Control
                          style={{ display: "block" }}
                          type="file"
                          placeholder={t("upload_video_thumbnail_placeholder")}
                          name="preview_file"
                          width="50%"
                          className="form-control"
                          accept=".gif,.jpg,.jpeg,.gif,.png,.jpg,.jpeg,.png"
                          onChange={(event) => handleVideopreviewImage(event)}
                        />
                      </Form.Group>
                      {videoThumbnail.videoPreviewImage !== "" ? (
                        <Row>
                          <Col sm={12} md={6} className="mb-3 mb-lg-4">
                            <div className="post-img-preview-sec m-0">
                              <Image
                                alt="#"
                                src={videoThumbnail.videoPreviewImage}
                                className="post-video-preview"
                              />
                            </div>
                          </Col>
                        </Row>
                      ) : null}
                    </>
                  ) : (
                    ""
                  )} */}
                                    {/* {videoThumbnailStatus === true && inputData.amount > 0 ? (
                    <>
                      <Form.Group className="md-mrg-btm mb-3 mb-lg-3">
                        <label className="text-muted m-1 mt-3 f-12 text-uppercase mb-3 mb-lg-3">
                          {t("upload_post_preview")}
                        </label>
                        <Form.Control
                          style={{ display: "block" }}
                          type="file"
                          placeholder={t("upload_post_preview_placeholder")}
                          name="preview_file"
                          width="50%"
                          className="form-control"
                          accept="video/mp4,video/x-m4v,video/*"
                          onChange={(event) => handleVideoPreview(event)}
                        />
                      </Form.Group>
                      {videoPreview.previewVideo !== "" ? (
                        <Row>
                          <Col sm={12} md={6} className="mb-3 mb-lg-4">
                            <div className="post-img-preview-sec m-0">
                              <div className="post-img-preview-sec my-3 my-lg-4">
                                <video
                                  autoplay
                                  controls
                                  id="myVideo"
                                  className="user-profile1 w-100"
                                >
                                  <source src={videoPreview.previewVideo} type="video/mp4" />
                                </video>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      ) : null}
                    </>
                  ) : (
                    ""
                  )} */}
                                </Col>

                                <Col sm={12} md={6} className="mt-3 mt-lg-4">
                                    {localStorage.getItem("is_content_creator") == 2 ? (
                                        <div>
                                            <Form.Group className="mb-0">
                                                <Form.Label className="edit-input-label mb-3 mb-lg-3">
                                                    {t("Post Type")}
                                                </Form.Label>
                                                <div className="postTypeMenu">
                                                    <Button
                                                        className={postType == 'image' ? 'gradient-btn postBtn gradientcolor' : ''}
                                                        onClick={() => handlePostTypeChange('image')}>
                                                        {t("post_type_picture")}
                                                    </Button>
                                                    <Button
                                                        className={postType == 'video' ? 'gradient-btn postBtn gradientcolor' : ''}
                                                        onClick={() => handlePostTypeChange('video')}>
                                                        {t("post_type_video")}
                                                    </Button>
                                                    <Button
                                                        className={postType == 'audio' ? 'gradient-btn postBtn gradientcolor' : ''}
                                                        onClick={() => handlePostTypeChange('audio')}>
                                                        {t("post_type_audio")}
                                                    </Button>
                                                </div>
                                            </Form.Group>
                                            <div className="left-half post-write">
                                                <Button className="btn gradient-btn postBtn gradientcolor uploadButton text-uppercase mt-0 mt-md-3">
                                                    <Form.Group className="mb-0">
                                                        <Form.Control
                                                            id="fileupload"
                                                            type="file"
                                                            multiple="multiple"
                                                            disabled={disableImage ? true : false}
                                                            accept={postType === 'image' ? imageFormats : postType === 'video' ? videoFormats : audioFormats}
                                                            onChange={(event) => handleUpload(event, postType)}
                                                            name="post_files"/>
                                                        <Form.Label
                                                            id="attach_file"
                                                            for="fileupload"
                                                            className="chat-attach_file">
                                                            {t("media_upload")}
                                                        </Form.Label>
                                                    </Form.Group>
                                                </Button>
                                                {videoTitle !== "" ? (
                                                    <div className="post-title-content create-post-video-title">
                                                        <h4>{videoTitle}</h4>
                                                    </div>
                                                ) : null}
                                                {audioTitle !== "" ? (
                                                    <div className="post-title-content create-post-video-title">
                                                        <h4>{audioTitle}</h4>
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                    {previewImage && postFileData ?
                                        <Row>
                                            {postFileData.map((image, index) => (
                                                <Col sm={12} md={6}>
                                                    <div className="post-img-preview-sec">
                                                        <Link to="#" onClick={(event) => handleClose(event, image)}>
                                                            <i className="far fa-times-circle"></i>
                                                        </Link>
                                                        <Image
                                                            alt="#"
                                                            src={image}
                                                            className="post-video-preview"
                                                        />
                                                    </div>
                                                </Col>
                                            ))}
                                        </Row>
                                        : null}
                                    {videoPreviewUrl && postFileData ? (
                                        <Row>
                                            {postFileData.map((video, index) => (
                                                <Col sm={12} md={12}>
                                                    <div key={index} className="post-img-preview-sec my-3 my-lg-4">
                                                        <video
                                                            autoplay
                                                            controls
                                                            id="myVideo"
                                                            className="user-profile1 create-post-video"
                                                        >
                                                            <source src={video} type="video/mp4"/>
                                                        </video>
                                                        <Link to="#" onClick={(event) => handleClose(event, video)}
                                                              className="close-video">
                                                            <i className="far fa-window-close"></i>
                                                        </Link>
                                                    </div>
                                                </Col>
                                            ))}
                                        </Row>
                                    ) : null}
                                    {audioThumbnail && postFileData ? (
                                        <Row>
                                            {postFileData.map((audio, index) => (
                                                <Col sm={12} md={12}>
                                                    <div className="post-img-preview-sec">
                                                        <audio
                                                            controls
                                                            id="myVideo"
                                                            className="user-profile1"
                                                        >
                                                            <source src={audio} type="audio/mp3"/>
                                                        </audio>
                                                        <Link to="#" onClick={(event) => handleClose(event, audio)}
                                                              className="close-audio">
                                                            <i className="far fa-window-close"></i>
                                                        </Link>
                                                    </div>
                                                </Col>
                                            ))}
                                        </Row>
                                    ) : null}
                                </Col>

                                <Col sm={12} md={6} className="mt-3 mt-lg-4">
                                    <>
                                        <Form.Group className="md-mrg-btm mt-3 mt-lg-4">
                                            <Form.Label className="edit-input-label mb-3 mb-lg-3">
                                                {` Enable Fundraising ?  `}
                                                <input value="test" type="checkbox" onChange={handleChange}/>
                                            </Form.Label>


                                            {isCampaign ?

                                                <div className="campaign-input-box">
                                                    <input
                                                        className="campaign-input-field searchBox"
                                                        placeholder="Your target"
                                                        type="number"
                                                        name="opt_1"
                                                        pattern="[0-9]*"
                                                        min="1"
                                                        inputmode="numeric"
                                                        value={campaignGoalAmount}
                                                        onChange={handleAmountChange}
                                                    />
                                                </div>

                                                :
                                                null
                                            }


                                            {campaignGoalAmount > 1 ?
                                                <>
                                                    {
                                                        multipleInputs.slice(0, 4).map((input, index) => {
                                                            return (
                                                                <>
                                                                    <div key={index} style={{
                                                                        marginTop: '20px',
                                                                        position: 'relative'
                                                                    }}>

                                                                        <div className="campaign-input-box">
                                                                            <input
                                                                                className="campaign-input-field searchBox"
                                                                                placeholder="Enter option"
                                                                                type="number"
                                                                                name="name"
                                                                                pattern="[0-9]*"
                                                                                min="1"
                                                                                inputmode="numeric"
                                                                                value={input.name}
                                                                                onChange={(e) => handleMultipleInputChange(e, index)}
                                                                                key={index}
                                                                            />
                                                                        </div>
                                                                        {index == 0 ?
                                                                            null
                                                                            :
                                                                            <div class="forget-password error-message">
                                                                                <p id="one">
                                                                                    <a type="button" class="forgot-link"
                                                                                       onClick={(e) => removeInputFields(e, index)}>Remove</a>
                                                                                </p>
                                                                            </div>

                                                                        }
                                                                    </div>
                                                                    <br/>
                                                                    <div class="forget-password error-message">
                                                                        <p id="one">
                                                                            <a type="button" class="forgot-link"
                                                                               onClick={addInputFields}> {addOptionMsg} </a>
                                                                        </p>
                                                                    </div>

                                                                    {/* {index == 4 &&
                                      <div class="forget-password error-message">
                                        <p id="one">
                                          <a type="button" class="forgot-link"> You can add maximum 4 prefered option </a>
                                        </p>
                                      </div>
                                    } */}
                                                                </>
                                                            )
                                                        })
                                                    }
                                                </>
                                                : null
                                            }
                                            {multipleInputError &&
                                                <p style={{
                                                    "float": "left",
                                                    "fontSize": "14px",
                                                    "color": "#e34498"
                                                }}>Number should be less target amount</p>
                                            }

                                        </Form.Group>
                                    </>

                                </Col>
                            </Row>
                        </Form>
                        <br/>
                    </div>
                ) : (
                    ""
                )}{" "}
            </Container>
            {localStorage.getItem("is_content_creator") != 2 && (
                <ContentCreatorSteps/>
            )}
        </div>
    );
};

const mapStateToPros = (state) => ({
    savePost: state.post.savePost,
    fileUpload: state.post.fileUpload,
    fileRemove: state.post.fileRemove,
    searchUser: state.home.searchUser,
    postCategories: state.post.postCategories,
});

function mapDispatchToProps(dispatch) {
    return {dispatch};
}

export default connect(
    mapStateToPros,
    mapDispatchToProps
)(translate(CreatePostIndex));
