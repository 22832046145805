import React, {useEffect} from "react";

function useScript(src) {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = src;
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, [src]);
}

const HomepageLayout = (props) => {


    useScript("/home-assets/js/bootstrap.min.js");
    useScript("/home-assets/js/custom.js");
    const timestamp = new Date().getTime(); //build timestamp
    return (<>
        <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css"/>
        <link rel="stylesheet" type="text/css" href={`/home-assets/css/bootstrap.min.css?t=${timestamp}`}/>
        <link rel="stylesheet" type="text/css" href={`/home-assets/css/slick.css?t=${timestamp}`}/>
        <link rel="stylesheet" type="text/css" href={`/home-assets/css/style.css?t=${timestamp}`}/>
        <link rel="stylesheet" type="text/css" href={`/home-assets/css/responsive.css?t=${timestamp}`}/>

        <div className="main-wrpper home-assets">
            <div className="home-header">
                <div className="container">
                    <img src="/home-assets/images/logo.svg"
                         style={{"margin":"0 auto", "width":"350px"}}
                         alt="Logo"/>
                </div>
            </div>

                {props.children}

                <footer>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-5 col-sm-12">
                                <div className="footer-abt">
                                    <img src="/home-assets/images/logo.svg" alt="footer-logo"/>

                                    <p>A new paradigm in the adult world powered by Crypto & Card Payments.</p>

                                    <div className="social-box">
                                        <a href="https://www.tiktok.com/@wickedfanscom" target="_blank"><img
                                            src="/home-assets/images/tiktok-ic.png" alt=""/></a>
                                        <a href="https://twitter.com/wickedfanscom" target="_blank"><i
                                            className="fab fa-twitter fa-fw"></i></a>
                                        <a href="https://www.facebook.com/wickedfanscom" target="_blank"><i
                                            className="fab fa-facebook-f fa-fw"></i></a>
                                        <a href="http://youtube.com/@WickedFans" target="_blank"><i
                                            className="fab fa-youtube fa-fw"></i></a>
                                        <a href="http://instagram.com/wickedfans" target="_blank"><i
                                            className="fab fa-instagram fa-fw"></i></a>
                                        <a href="https://discord.gg/VHbbCKDheq" target="_blank"><i
                                            className="fab fa-discord fa-fw"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-7 col-sm-12">
                                <div className="footer-links">
                                    <div className="quicklinks">
                                        <h3>Company</h3>

                                        <ul>
                                            <li><a href="/">Home</a></li>
                                            <li><a href="/explore">Explore</a></li>
                                            <li><a href="/explore">Feed</a></li>
                                            <li><a href="/explore">Discover</a></li>
                                        </ul>
                                    </div>
                                    <div className="quicklinks">
                                        <h3>Legal</h3>

                                        <ul>
                                            <li><a href="/legal">Legal Overview</a></li>
                                            <li><a href="/terms">Terms Of Service</a></li>
                                            <li><a href="/privacy">Privacy Policy</a></li>
                                            <li><a href="/privacy">Under 18 Disclaimer</a></li>
                                            <li><a href="/privacy">Creator Agreement</a></li>
                                        </ul>
                                    </div>
                                    <div className="quicklinks">
                                        <h3>Help</h3>

                                        <ul>
                                            <li><a href="/about-us">Contact</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
                <div className="copyright">
                    <div className="container">
                        <p>@2023 <a href="/">WickedFans</a> all right reserved.</p>
                    </div>
                </div>
            </div>

        </>
        );

        }

        export default HomepageLayout;
