import {all, put, select, takeLatest} from "redux-saga/effects";
import {changePasswordFailure, changePasswordSuccess,} from "../actions/UserAction";
import api from "../../Environment";
import {CHANGE_PASSWORD_START} from "../actions/ActionConstant";
import {createNotification} from "react-redux-notify";
import {getErrorNotificationMessage, getSuccessNotificationMessage,} from "../../components/helper/NotificationMessage";

import {checkLogoutStatus,} from "../actions/ErrorAction";

function* changePasswordAPI() {
  try {
    const inputData = yield select(
      (state) => state.changePassword.inputData.data
    );
    const response = yield api.postMethod("change_password", inputData);
    yield put(changePasswordSuccess(response.data.data));
    if (response.data.success) {
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
      
        window.location.assign("/logout");
    
    } else {
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(checkLogoutStatus(response.data));
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(changePasswordFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

export default function* pageSaga() {
  yield all([yield takeLatest(CHANGE_PASSWORD_START, changePasswordAPI)]);
}
